.mint-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 70px);
    margin-top: -50px;
}

.mint-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
}

.mint-option {
  text-align: center;
}

.mint-button {
  font-family: 'VT323', monospace;
  background: none;
  color: white;
  border: 2px solid white;
  font-size: 30px;
  padding: 10px 20px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: 50px;
}

/* Set the background color to transparent and add a golden border */
.golden-button {
  background-color: transparent;
  border: 2px solid #f8d76d;
  color: #f8d76d;
  position: relative;
  overflow: hidden;
}

/* Add a shiny gradient effect */
.golden-button::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -200%;
  width: 200%;
  height: 200%;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 25%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 75%
  );
  animation: moveShine 2.75s linear infinite;
}

/* Animate the movement of the shiny gradient */
@keyframes moveShine {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(200%);
  }
}

/* Add a shiny effect to the text */
.golden-button::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: rgba(255, 255, 255, 0.5);
  z-index: 1;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 25%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 75%
  );
  animation: moveShine 2.75s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.mint-note {
  font-family: 'VT323', monospace;
  font-size: 30px;
  color: white;
  text-align: center;
  margin-top: 50px;
}

p {
  margin: 0;
  font-size: 23px;
}

.connect-wallet-button {
  position: absolute;
  top: 20px;
  right: 20px;
  font-family: "VT323", monospace;
  background: none;
  color: white;
  border: 2px solid white;
  font-size: 23px;
  padding: 10px 20px;
  cursor: pointer;
}

.network-error {
  color: red;
  font-size: 25px;
}