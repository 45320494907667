#intro-title {
    display: flex;
    font-family: 'VT323', monospace;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    font-weight: 800;
}

#adam-title,
#eve-title {
    height: 90px;
    width: 60px;
    object-fit: cover;
}

#eve-title {
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
}

#intro-title-front {
    padding-right: 5px;
}

#intro-title-back {
    padding-left: 2px;
}

#story {
    margin: 100px 100px;
    font-size: 25px;
}

.fade-in {
    animation: fadeIn 10s;
    margin-top: 20px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

#enter-button {
    background: white;
    border: none;
    font-family: 'MedievalSharp', cursive;
    font-size: 35px;
    align-self: right;
    cursor: pointer;
}

#enter-button-container {
    display: flex;
    justify-content: right;
    padding-top: 150px;
    padding-right: 30px;
}

/* Media queries for laptop and desktop screens */
@media (min-width: 768px) {
    #intro-title {
        font-size: 70px;
    }

    #adam-title,
    #eve-title {
        height: 90px;
        width: 60;
    }

    #story {
        margin: 100px 150px;
        font-size: 35px;
    }

    #enter-button {
        font-size: 45px;
    }

    #enter-button-container {
        padding-top: 150px;
        padding-right: 50px;
    }
}
