#cycle-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

.cycle-image-container {
width: 100%;
max-width: 600px; /* You can adjust this value based on the desired maximum width */
overflow: hidden;
}

#cycle {
width: 100%;
height: auto;
}

#cycle-title {
text-align: center;
padding-top: 100px;
padding-bottom: 50px;
font-size: 40px;
}

@media screen and (max-width: 767px) {
#cycle-title {
font-size: 32px;
}

h2 {
font-size: 18px;
}
}
