#fruit-title {
    height: 90px;
    width: 90px;
    object-fit: cover;
    padding: 10px 5px;
}

.title-button {
    font-family: 'VT323', monospace;
    background: none;
    color: white;
    border: none;
    font-size: 30px;
    margin: 40px;
    cursor: pointer;
}

#title {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 10;
    padding-top: 10px;
    background-color: black;
  }

body {
    margin: 70px 0 0 0;
}

#home-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'VT323', monospace;
    font-size: 50px;
}

#mint-button,
#claim-button {
    border: 2px solid lightgray;
    border-radius: 10px;
    padding: 5px 35px;
}