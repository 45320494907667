body {
    /* font-family: 'MedievalSharp', cursive; */
    font-family: 'VT323', monospace;
    /* font-family: 'DotGothic16', sans-serif; */
    margin: 0;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
}

img {
    height: 20vh;
    width: auto;
}