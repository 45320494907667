#home-text-container {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    padding-top: 100px;
    padding-left: 50px;
}

#home-text {
    font-family: 'VT323', monospace;
    border-radius: 20px;
    width: 63vw;
    color: white;
}

#home-text p {
    padding: 10px 40px;
    font-size: 30px;
}

p {
    padding: 10px 40px;
    font-size: 30px;
}

h2 {
    padding-left: 40px;
}
