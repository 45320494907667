#grid-img-container {
  padding-top: 150px;
  position: relative;
  width: 100%;
  height: 700px;
}

.grid-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.grid-item {
  position: absolute;
  transform-origin: center center;
  padding: 10px;
  text-align: center;
  font-size: 20px;
  width: 150px;
  height: 150px;
  margin-top: -75px;
  margin-left: -75px;
}

img {
  height: 100px;
  padding-top: 10px;
  width: 100%;
  object-fit: contain;
}

.grid-item p {
  margin-top: 10px;
}

@media screen and (max-width: 767px) {
  .grid-item {
    font-size: 16px;
  }
}
